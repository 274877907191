import React from "react";
import './App.css';
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Allproduct from "./components/pages/Allproduct";
import Fridge from "./components/pages/Fridge";
import Furniture from "./components/pages/Furniture";
import Washer from "./components/pages/Washer";
import Air from "./components/pages/Air";
import Led from "./components/pages/Led";
import Support from "./components/pages/Support";
import Contact from "./components/pages/Contact";
import Happy from "./components/pages/Happy";
import Videoproduct from "./components/pages/Videoproduct";
function App() {
  return (
    <Router>
      
  
      
      <div>
      
       
        
        <Routes>
        <Route axact path="/" element={<Home/>}/>
        <Route axact path="/others-product" element={<Allproduct/>}/>
        <Route axact path="/fridge" element={<Fridge/>}/>
         <Route axact path="/furniture" element={<Furniture/>}/>
         <Route axact path="/washer" element={<Washer/>}/>
          <Route axact path="/ac" element={<Air/>}/>
          <Route axact path="/led" element={<Led/>}/>
          <Route axact path="/support" element={<Support/>}/>
          <Route axact path="/contact" element={<Contact/>}/>
          <Route axact path="/happy" element={<Happy/>}/>
          <Route axact path="/videolatest" element={<Videoproduct/>}/>
        </Routes>

  <Footer/>
    </div>
   
    </Router>
   
  );
}

export default App;
