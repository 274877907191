

import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyCctzNum8JoSC6Rj-Wb9WsJ3NmtwQ_1IQU",
    authDomain: "biswas-furniture.firebaseapp.com",
    projectId: "biswas-furniture",
    storageBucket: "biswas-furniture.appspot.com",
    messagingSenderId: "965163174441",
    appId: "1:965163174441:web:74dc39554c80df1d5eea38",
    measurementId: "G-YCKVYW9JYP"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;