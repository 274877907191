import React from 'react';
import {Link} from 'react-router-dom';

function Mapg(props) {
    return (
<>
<div className="album py-1">
        
        <div className="container">

            <div className="row">
       
        
           
         
          

<iframe className="iframe-fluid"  width="1100" height="436" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=Biswas%20Furniture%2C%20Karimpur%2C%20Anandapally%2C%20741152%2C%20Nadia+(Title)&amp;ie=UTF8&amp;t=&amp;z=9&amp;iwloc=B&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
</div>

</div>
</div>


</>
);
}

export default Mapg;